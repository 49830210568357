var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(_vm.accordionId),expression:"accordionId"}],staticClass:"project-dashboard-btn  d-flex align-items-center",attrs:{"block":""}},[_c('span',{staticClass:"featherspan-dashboard",style:(_vm.cssVars)},[_c('b-icon',{staticClass:"collapseicon-dashboard",attrs:{"icon":"Caret-right-fill","size":"50"}})],1),_c('span',{staticClass:"project-title",style:({ color: '#' + _vm.taskData[0].color })},[_vm._v(" "+_vm._s(_vm.taskData[0].name)+" ")]),_c('span',{staticClass:"total-task-project"},[_vm._v(_vm._s(_vm.taskData.length)+" Tasks ")])])],1),_c('b-collapse',{attrs:{"id":_vm.accordionId,"visible":"","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',{staticClass:"p-0"},[_c('div',{staticClass:"card-body-listing"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-table',{staticClass:"\n                  scrollable-element-dashboard\n                  project-task-table dashboard-accordien-table\n                  table-responsive\n                  mb-0\n                ",attrs:{"responsive":"","per-page":_vm.perPage,"current-page":_vm.currentPage,"items":_vm.taskData,"fields":_vm.fields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[(
                        data.item &&
                          data.item.number &&
                          data.item.type === 'bug'
                      )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary",modifiers:{"hover":true,"v-secondary":true}}],staticClass:"bug-fill-span",attrs:{"title":"Bug"}},[_c('b-icon',{attrs:{"icon":"Bug-fill"}})],1):(
                        data.item &&
                          data.item.number &&
                          data.item.type === 'story'
                      )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Story'),expression:"'Story'",modifiers:{"hover":true,"top":true}}],staticClass:"story-fill-span"},[_c('b-icon',{attrs:{"icon":"Bookmark-fill"}})],1):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Task'),expression:"'Task'",modifiers:{"hover":true,"bottom":true}}],staticClass:"improvement-fill-span"},[_c('img',{attrs:{"src":require("../../assets/images/Image/improvement.svg")}})]),_c('div',[_c('p',{staticClass:"project-title-dashboard"},[_vm._v(" "+_vm._s(data.item.task_title)+" ")]),_c('p',{staticClass:"milestone-sprint-dashboard"},[( data &&
                            data.item &&
                            data.item.milestone_title

                          )?_c('span',[_vm._v(" "+_vm._s(data.item.milestone_title)+" ")]):_vm._e(),(
                            data.item &&
                              data.item.sprint_title
                          )?_c('span',[_vm._v("-> "+_vm._s(data.item.sprint_title)+" ")]):_vm._e()])])])]}},{key:"cell(ticketnr)",fn:function(data){return [_c('div',[(data.item)?_c('span',{staticClass:"ticket"},[_vm._v(" "+_vm._s(data.item.task_number ? data.item.task_number : "-"))]):_vm._e(),(!data.item)?_c('span',{staticClass:"ticket"},[_vm._v(" -")]):_vm._e()])]}},{key:"cell(status)",fn:function(data){return [_c('div',[(data.item.status)?_c('span',{staticClass:"status-progress"},[_vm._v(_vm._s(data.item.status_name))]):_c('span',[_vm._v("-")])])]}},{key:"cell(timeestimated)",fn:function(data){return [(
                      data.item &&
                        data.item.task_number &&
                        data.item.estimated_hours
                    )?_c('div',[_c('span',{staticClass:"esttime"},[_vm._v(" "+_vm._s(_vm._f("hoursInTimeFormat")(data.item.estimated_hours)))])]):_vm._e(),(
                      !data.item ||
                        !data.item.task_number ||
                        !data.item.estimated_hours
                    )?_c('div',{staticClass:"clock-icon-span"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"icon":"ClockIcon","size":"18","title":"Not Mentioned"}})],1):_vm._e()]}},{key:"cell(timespent)",fn:function(data){return [(data.item && data.item.project_task_spent_minutes > 0)?_c('span',{staticClass:"esttime",style:(data.item.estimated_hours
                        ? data.item.project_task_spent_minutes / 60 >
                          data.item.estimated_hours
                          ? { color: 'red' }
                          : { color: 'green' }
                        : null)},[_vm._v(" "+_vm._s(_vm._f("hoursInTimeFormat")((data.item.project_task_spent_minutes / 60).toFixed(2))))]):_vm._e(),(
                      data.item &&
                        data.item.project_task_spent_minutes == null
                    )?_c('div',{staticClass:"clock-icon-span"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"icon":"ClockIcon","size":"18","title":"Not Time Spent"}})],1):_vm._e(),(
                      !data.item || data.item.project_task_spent_minutes == 0
                    )?_c('div',{staticClass:"clock-icon-span"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"icon":"ClockIcon","size":"18","title":"Not Time Spent"}})],1):_vm._e(),(
                      !data.item && data.item.project_task_spent_minutes !== 0
                    )?_c('span',{staticClass:"esttime"},[_vm._v(" "+_vm._s(_vm._f("hoursInTimeFormat")((data.item.project_task_spent_minutes / 60).toFixed(2)))+" ")]):_vm._e()]}},{key:"cell(duedate)",fn:function(data){return [(
                      data.item &&
                        data.item.task_number &&
                        data.item.due_date
                    )?_c('span',{staticClass:"esttime",style:(_vm.dateCompare(data.item.due_date)
                        ? { color: 'red' }
                        : { color: 'green' })},[_vm._v(_vm._s(_vm._f("timeDayFormat")(data.item.due_date))+" ")]):_vm._e(),(
                      !data.item ||
                        !data.item.task_number ||
                        !data.item.due_date
                    )?_c('div',{staticClass:"clock-icon-span"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"icon":"CalendarIcon","size":"18","title":"Not Mentioned"}})],1):_vm._e()]}},{key:"cell(priority)",fn:function(data){return [(
                      data.item &&
                        data.item.priority === 'high'
                    )?_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('High'),expression:"'High'",modifiers:{"hover":true,"top":true}}],staticClass:"flag-fill-priority",attrs:{"icon":"Flag-fill"}}):(
                      data.item &&
                        data.item.priority === 'med'
                    )?_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Medium'),expression:"'Medium'",modifiers:{"hover":true,"top":true}}],staticClass:"flag-fill-priority-medium",attrs:{"icon":"Flag-fill"}}):_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Low'),expression:"'Low'",modifiers:{"hover":true,"top":true}}],staticClass:"flag-fill-priority-low",attrs:{"icon":"Flag-fill"}})]}},{key:"cell(ticket)",fn:function(data){return [_c('div',{staticClass:"chervon-right",staticStyle:{},on:{"click":function($event){return _vm.redirectToTask(data.item)}}},[_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"28"}})],1)]}}])})],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }