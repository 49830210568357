<template>
  <div>
    <b-row class="match-height">
      <b-col
        v-if="leaves.length"
        cols="12"
        lg="6"
        xl="6"
        class="mb-2 p-0"
      >
        <div class="total-project-hour-dashboard dashtextShadow">
          <div class="d-flex align-items-start justify-content-between">
            <div>
              <h3 class="mb-0 total-hours-time upcoming-leave-title">
                Upcoming Leaves
              </h3>
            </div>
          </div>
          <div class="hr-line-dashboard" />

          <div class="all-project-progress">
            <table class="w-100">
              <thead class="upcoming-head">
                <tr>
                  <th class="w-50">
                    Employee Name
                  </th>
                  <th>Date</th>
                  <th v-if="leaves[0].hasOwnProperty('is_full_day')">
                    Leave Type
                  </th>
                  <th>Leave Status</th>
                </tr>
              </thead>
              <tbody class="upcoming-body m-50">
                <tr
                  v-for="leave, index in leaves"
                  :key="index"
                  class=""
                >
                  <td>
                    <div class="d-flex align-items-center">
                      <b-avatar
                        :text="(leave.first_name + ' ' + leave.last_name).split(' ').slice(0, 2).join(' ') | avatarText
                        "
                        :variant="randomVariant(1, leaves.length)"
                        class="mr-50"
                      />

                      <p
                        class="tbltxtWrap cursor-pointer"
                        @click="openUserProfile(leave.emp_id)"
                      >
                        {{ leave.first_name + ' ' + leave.last_name }}

                      </p>
                    </div>
                  </td>
                  <td>{{ leave.date | formatDateDDMMYYYY }}</td>
                  <td v-if="leave.hasOwnProperty('is_full_day')"> <b-badge
                    pill
                    variant="success"
                    class="p-75"
                  >
                    {{ leave.is_full_day ? 'Full Day' : 'Half Day' }}
                  </b-badge></td>
                  <td> <b-badge
                    pill
                    variant="success"
                    class="p-75"
                  >
                    {{ leave.is_auto_approved ? 'Auto Approved' : leave.is_approved ? 'Approved' : null }}
                  </b-badge></td>

                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </b-col>

      <b-col
        v-if="todayLeaves.length"
        cols="12"
        lg="6"
        xl="6"
        class="mb-2 pr-0"
      >
        <div class="total-project-hour-dashboard dashtextShadow">
          <div class="d-flex align-items-start justify-content-between">
            <div>
              <h3 class="mb-0 total-hours-time upcoming-leave-title">
                Today's Leaves

              </h3>
            </div>
          </div>
          <div class="hr-line-dashboard" />

          <div class="all-project-progress">
            <table class="w-100">
              <thead class="upcoming-head today-leave">
                <tr>
                  <th>
                    Employee Name
                  </th>
                  <th v-if="todayLeaves[0].hasOwnProperty('is_full_day')">
                    Leave Type
                  </th>

                </tr>
              </thead>
              <tbody class="upcoming-body m-50">
                <tr
                  v-for="leave, index in todayLeaves"
                  :key="index"
                  class=""
                >
                  <td>
                    <div class="d-flex align-items-center">
                      <b-avatar
                        :text="(leave.name).split(' ').slice(0, 2).join(' ') | avatarText
                        "
                        :variant="randomVariant(1, todayLeaves.length)"
                        class="mr-50"
                      />
                      <p
                        class="tbltxtWrap cursor-pointer"
                        @click="openUserProfile(leave.emp_id)"
                      >
                        {{ leave.name }}

                      </p>
                    </div>
                  </td>
                  <td v-if="leave.hasOwnProperty('is_full_day')"> <b-badge
                    pill
                    variant="success"
                    class="p-75"
                  >
                    {{ leave.is_full_day ? 'Full Day' : 'Half Day' }}
                  </b-badge></td>
                  <td>{{ leave.date | formatDateDDMMYYYY }}</td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </b-col>
      <b-col
        v-if="userInfo && !userInfo.is_resource_managing"
        cols="12"
        lg="6"
        xl="6"
        class="mb-2"
        :class="leaves.length == 0 ? 'pr-1 pl-0' : todayLeaves.length ? 'pr-0 pl-0' : 'pr-0'"
      >
        <ActivityLogList :user-id="userId" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ActivityLogList from '../../../views/Dashboard/activityLogs.vue'
import axios from "axios"
export default {
  name: 'ClockInOutActivity',
  components: {
    ActivityLogList,
  },
  props: {
    userId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      users: [],
      search: null,
      totalCount: 0,
      perPage: 10,
      currentPage: 1,
      leaves: [],
      todayLeaves: [],
      emp_ids: [],

    }
  },

  watch: {
    currentPage() {
      this.getupComingLeaveList()
    },
  },
  mounted() {
    this.getEmployeeIds()
  },
  methods: {
    async getEmployeeIds() {
      const response = await this.getHTTPPostResponse(
        'project/user/list',
        {},
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.emp_ids = data.employee_ids
        if (this.emp_ids && this.emp_ids.length > 0) {
          this.getupComingLeaveList()
          this.getTodayLeaveList()
        }
      }
    },

    async getupComingLeaveList() {
      const input = {
        sort_field: 'date',
        sort_order: 'asc',
        emp_id: this.emp_ids,
        type: 'upcoming',
        redis_key:  this.$store.state.app.redisKey
      }

      const url = `${process.env.VUE_APP_HRMS_SERVICE_URL}track-user-leaves`
      await axios
        .post(url, input,{
          headers: {
            redisKey: this.$store.state.app.redisKey,
            authToken: this.$store.state.app.authToken,
          }
        })
        .then(response => {
          this.leaves = response.data.data.leaves
        }).catch(error => {
          console.error('There was an error!', error)
        })
    },

    async getTodayLeaveList() {
      const input = {
        sort_field: 'date',
        sort_order: 'asc',
        emp_id: this.emp_ids,
        type: 'today',
        redis_key:  this.$store.state.app.redisKey
      }

      const url = `${process.env.VUE_APP_HRMS_SERVICE_URL}track-user-leaves`
      await axios
        .post(url, input,{
          headers: {
            redisKey: this.$store.state.app.redisKey,
            authToken: this.$store.state.app.authToken,
          }
        })
        .then(response => {
          this.todayLeaves = response.data.data.todaysLeave
        }).catch(error => {
          console.error('There was an error!', error)
        })
    },
  },
}
</script>
<style lang="scss">
@import "../../../assets/scss/variables/variables";

.upcoming-leave-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.upcoming-head tr th {
  text-align: start;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #5e5873;
  padding: 10px;
  text-transform: uppercase;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 11;
}

.today-leave tr th {
  z-index: 1;
}

.upcoming-body tr td {
  text-align: start;
  font-weight: 600;
  padding: 8px;
  font-size: 14px;
  line-height: 15px;
  color: #5e5873;
  text-transform: capitalize;
  width: 180px;
}
</style>
