<template>
  <div>
    <div class="facebook-card">
      <skeleton-loader-vue
        type="circle"
        :width="50"
        :height="50"
        animation="wave"
      />
    </div>
  </div>
</template>

<script>
import SkeletonLoaderVue from "skeleton-loader-vue"

export default {
  name: "CircleShimmer",
  components: {
    SkeletonLoaderVue,
  },
}
</script>

<style>
</style>