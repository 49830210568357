<template>
  <div>
    <b-card
      v-if="userInfo.role != 'HR'"
      no-body
      class="card-dashboard-section"
    >
      <b-card-header class="dashboard-header ">
        <b-card-title>
          <div class="dashbord-nav">
            <feather-icon
              icon="FileTextIcon"
              size="18"
            />
            <span>Dashboard </span>
          </div>
        </b-card-title>
        <div class="d-flex align-items-center user-navigation new-dashboard">
          <div
            v-if="userInfo.role === 'SA'"
            class="align-items-center d-flex mr-2"
          >
            <p>member:</p>
            <v-select
              v-model="userId"
              name="status"
              label="name"
              :options="users"
              :reduce="(user) => user.id"
              class="select-size-sm select-user-nav"
            >
              <template v-slot:option="option">
                {{ option.name }}
              </template>
              <template #selected-option="option">
                <div style="display: flex; align-items: baseline">
                  <span class="select-user-name">{{ option.name }} </span>
                </div>
              </template>
            </v-select>
          </div>
          <div
            v-if="userInfo.is_resource_managing != 1"
            class="d-flex align-items-center"
          >
            <p>Pending-timesheet:</p>
            <v-select
              v-if="week_loader || weekData.length"
              v-model="pendingWeek"
              name="status"
              label="start_date"
              :options="weekData"
              class="select-size-sm select-user-nav fix-height"
              :searchable="false"
            >
              <template v-slot:option="option">
                <span>
                  {{ option.start_date | formatDateDDMMYYYY }} to
                  {{ option.end_date | formatDateDDMMYYYY }}
                </span>
              </template>

              <template #selected-option="option">
                <div style="display: flex; align-items: baseline">
                  <span class="select-user-name">
                    {{ option.start_date | formatDateDDMMYYYY }} to
                    {{ option.end_date | formatDateDDMMYYYY }}
                  </span>
                </div>
              </template>
            </v-select>
            <span
              v-else
              class="text-success bordered-span"
            >
              No pending timesheet.
            </span>
            <button
              v-show="userInfo.role !== 'SA' && pendingWeek && weekData.length"
              class="submit-sheet ml-1 mt-0 py-50"
              @click="submitTimeSheet()"
            >
              check-timeSheet
            </button>
          </div>

          <Help
            v-show="$store.state.app.userManualList"
            code="General"
          />

        </div>
      </b-card-header>
    </b-card>

    <div
      v-if="userInfo.role === 'SA' || userInfo.role === 'EMP'"
      class="mb-5"
    >
      <b-row
        v-if="projectTasks"
        class="main-projectlist-row mx-0"
      >
        <!-- project accordion table  -->
        <b-col
          v-for="(taskData, key) in projectTasks"
          :key="key"
          cols="12 p-0"
        >
          <!--accordion-->
          <DashboardAccordion
            :task-data="taskData"
            :accordion-id="key"
            :color="'#' + taskData[0].color"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="userInfo.role === 'EMP' && userInfo.is_resource_managing === true
        "
        class="match-height maindashboardList "
        style="  padding-bottom: 0px"
      >
        <b-col
          cols="12 mr-0"
          lg="4"
          xl="4"
        >
          <ClockInOutActivity />
        </b-col>
        <b-col
          cols="12 pl-0"
          lg="8"
          xl="8"
        >

          <div>
            <ManagerDashBoard />
          </div>

        </b-col>

      </b-row>

      <b-row
        v-if="!userInfo.role == 'EMP' || userInfo.users.length || userInfo.manage_by.length || userInfo.is_resource_managing === true"
        :class="userInfo.role === 'EMP' && userInfo.is_resource_managing === true ? 'mt-1' : null"
      >
        <b-col
          cols="12"
          lg="12"
          xl="12"
          class=" mx-0"
        >
          <MemberWatchList />
        </b-col>
      </b-row>
      <b-row class="match-height mx-0">
        <b-col
          cols="12"
          lg="6"
          xl="4"
          class="pl-0"
        >
          <dashboardUserLogList :user-id="userId" />
        </b-col>
        <!-- total hour progress card -->
        <b-col
          cols="12 mt-1 "
          lg="6"
          xl="3"
          class="pl-0"
        >
          <projectLogHours :user-id="userId" />
        </b-col>

        <b-col
          cols="12 mt-1 p-0"
          lg="6"
          xl="5"
        >
          <ActivityLogList
            v-if="userInfo.is_resource_managing == true || userInfo.role == 'SA'"
            :user-id="userId"
          />
          <ProjectWatchlist v-if="userInfo.is_resource_managing != true && userInfo.role != 'SA'" />
        </b-col>
      </b-row>

      <b-row
        v-if="userInfo.role === 'SA'"
        class="match-height mx-0"
      >
        <b-col
          cols="12 pl-0"
          lg="4"
          xl="4"
        >
          <project-list />
        </b-col>
        <b-col
          cols="12"
          lg="4"
          xl="4"
          class="mt-1 p-0"
        >
          <ProjectWatchlist />

        </b-col>
      </b-row>

      <b-row
        v-if="userInfo.role === 'EMP'
        "
        class="match-height maindashboardList m-0 p-0 mt-1 mb-2"
        style="  padding-bottom: 0px"
      >
        <b-col
          cols="12 mr-0"
          lg="12"
          xl="12"
        >
          <UpComingLeaveCard :user-id="userId" /> <!-- UpcomingleaveCard & TodayleaveCard & projectWatchlistCard -->

        </b-col>
      </b-row>
    </div>
    <div v-if="userInfo.role === 'HR'">
      <Clock />
    </div>

  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BCardHeader,
  VBTooltip,
} from 'bootstrap-vue'
import moment from 'moment'
import { eventBus } from '@/main'
import Help from '@/components/HelpDesk/Help.vue'
import DashboardAccordion from '../components/Dashboard-accordion/dashboardAccordion.vue'
import Clock from './HrDashboard.vue'
import ProjectList from '../components/Dashboard-accordion/recentProjectList.vue'
import ManagerDashBoard from './ManagerDashBoard.vue'
import ClockInOutActivity from '../@core/components/clockInOut/ClockInOutActivity.vue'
import UpComingLeaveCard from '../@core/components/clockInOut/upComingLeave.vue'
import dashboardUserLogList from './Dashboard/dashboardUserLogList.vue'
import projectLogHours from './Dashboard/projectLogHours.vue'
import ActivityLogList from './Dashboard/activityLogs.vue'
import ProjectWatchlist from './Dashboard/projectWatchlist.vue'
import MemberWatchList from './Dashboard/memberWatchList.vue'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BCardHeader,
    Clock,
    DashboardAccordion,
    ProjectList,
    Help,
    ManagerDashBoard,
    ClockInOutActivity,
    UpComingLeaveCard,
    dashboardUserLogList,
    projectLogHours,
    ActivityLogList,
    ProjectWatchlist,
    MemberWatchList,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      /* new variable added */
      users: [],
      totalCount: 0,

      currentPage: 1,
      perPage: 20,
      data: null,
      userCount: null,
      userId: null,
      projectTasks: null,
      userProjectLogList: [],
      totalProjectMinutes: null,
      progressBarData: null,
      start_date: null,
      end_date: null,
      dateRange: 'Today',
      loader: true,
      week_loader: false,
      load_userLog: false,
      load_userProject: false,
      load_activity: false,
      pendingWeek: [],
      weekData: [],
      // load_userLog: false,
      /* ui side declared */
      directives: {
        'b-tooltip': VBTooltip,
      },
      activityLogs: [],
      reloadTaskList: false,
    }
  },
  computed: {
    setWeekData() {
      const weeks = []
      this.weekData.forEach(element => {
        weeks.push({
          label: `${element.start_date} to ${element.end_date}`,
          value: `${element.start_date} to ${element.end_date}`,
        })
      })
      return weeks
    },
  },
  watch: {
    /**
     * @description on User Change call userProjectTaskList();
     */
    userId(nv) {
      this.dateRange = 'Today'
      this.userProjectTaskList()
      this.getPendingWeek()

      if ((this.userInfo && this.userInfo.id === nv)) {
        this.routeQueryUpdate({})
      } else {
        this.routeQueryUpdate({ userId: nv })
      }
    },
  },
  mounted() {
    this.load_userLog = true
    this.load_userProject = true
    this.load_activity = true
    this.week_loader = true
    this.userListCalling()

    this.start_date = moment().format('YYYY-MM-DD')
    this.end_date = moment().format('YYYY-MM-DD')
    this.getProjectTaskStatusData()
    eventBus.$on('actionLog', data => {
      if (data) {
        this.getProjectTaskStatusData()
      }
    })
    eventBus.$on('loadTaskListInDashboard', data => {
      if (data) {
        this.userProjectTaskList()
      }
    })
  },
  methods: {
    async userListCalling() {
      if (this.$store.state.app.usersList.length === 0) {
        this.users = await this.userList()
      } else {
        this.users = this.$store.state.app.usersList
      }
      if (this.$route.query && this.$route.query.userId) {
        this.userId = this.$route.query.userId
      } else {
        this.userId = this.userInfo.id
      }
    },

    /**
     * Get Employee IN OUT Action Log
     * @returns data [Action Log Data]
     */
    async getProjectTaskStatusData() {
      if (this.userInfo.role !== 'HR') {
        return
      } // condition for if user role not hr then not call api
      this.loader = true
      const input = { date: moment().format('YYYY-MM-DD') }

      const response = await this.getHTTPPostResponse(
        'clock-in-out/dashboard',
        input,
        false,
      )
      if (response && response.data) {
        const data = response.data.dashboard
        this.data = data
        this.loader = false
      }
    },

    /**
     * Assigned Project not completed user Task  list
     * @return projectTasks
     */
    async userProjectTaskList() {
      this.loader = true
      const input = {
        user_id: this.userId,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/work-dashboard/user-project-task-log-list',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.projectTasks = data.user_project_task_log_list
        this.loader = false
      }
    },
    /**
     * Get Pending timesheet week user data date
     */
    async getPendingWeek() {
      this.pendingWeek = null
      this.week_loader = true
      const input = {
        user_id: this.userId,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/timesheet/get-timesheet-week',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.weekData = data
        this.pendingWeek = {
          start_date:
            this.weekData && this.weekData.length
              ? this.weekData[0].start_date
              : null,
          end_date:
            this.weekData && this.weekData.length
              ? this.weekData[0].end_date
              : null,
        }
        this.week_loader = false
      }
    },
    /**
     * Submit timeSheet
     */
    submitTimeSheet() {
      const start_date = this.pendingWeek ? this.pendingWeek.start_date : null
      const end_date = this.pendingWeek ? this.pendingWeek.end_date : null
      if (this.pendingWeek.end_date && this.pendingWeek.start_date) {
        this.$router.push({
          name: 'timeSheetView',
          query: { startDate: start_date, endDate: end_date },
        })
      }
    },
  },
}
</script>
<style lang="scss">
.no-data-dashboard {
  width: 50%;
}

.generalshimmer .total-project-hour-dashboard {
  height: 500px !important;
}

.generalshimmer .col {
  height: 500px !important;
  border-radius: 16px !important;
}

.syncBtn {
  border-radius: 5px;
  font-size: 16px;
  border: none;
  padding: 5px 10px;
  text-transform: capitalize;
  background-color: #2178fb;
  color: #fff;

  &:hover {
    box-shadow: none !important;
    color: #fff;
  }
}

.submit-sheet {
  background-color: #2178fb;
  border: transparent;
  color: #ffffff;
  padding: 7px 10px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  text-transform: capitalize;
  margin-left: 5px;
}

.bordered-span {
  border: 1px solid rgba(0, 0, 0, 0.183);
  border-radius: 5px;
  width: fit-content;
  padding: 5px 30px;
}

.see-more-btn {
  background-color: transparent;
  border-color: transparent;
  color: #2178fb !important;
  border: 1px solid transparent;
}

.dashboard-pagination .table-pagination {
  margin-bottom: 1rem;

  p,
  ul {
    margin-top: 10px !important;
  }
}
</style>
